.intro-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
}

.intro-page h1 {
    font-size: 56px;
}

.intro-page h1 span {
    color: var(--blue-color);
}

.intro-page {
    text-align: center;
}