.navbar {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 15px 70px;
    border-bottom: var(--thin-border);
    background: linear-gradient(to bottom, #dcecfc, #ffffff);
}

.nav-items {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 10px;
}

.burger-nav-items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}

.nav-items li a,
.burger-nav-items li a {
    text-decoration: none;
    color: var(--text-color);
}

.navbar img {
    width: 150px;
}