.customers {
    margin-top: 65px;
}

.companies {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    list-style: none;
    margin-top: 30px;
}