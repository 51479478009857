.section-header {
  text-align: center;
  margin-bottom: 20px;
}

.section-header h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
}

.section-header p {
  color: #666;
  font-size: 16px;
  line-height: 1.5;
}
