:root {
  /* ===== Colors ===== */
  --primary-color: #244c64;
  --primary-color-hover: #858c93;
  --primary-text-color: #383838;
  --text-color: #0C0C0C;
  --light-grey: #f0f0f0;
  --white-color: #fff;
  --blue-color: #299fdc;

  /* ===== borders ===== */
  --thin-border: 1px solid var(--light-grey);

  /* ===== border radius ===== */
  --border-radius: 30px;
}

/* === Custom Scroll Bar CSS === */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  /* background: var(--primary-color); */
  background: grey;
  border-radius: 12px;
  transition: all 0.3s ease;
}

::-webkit-scrollbar-thumb:hover {
  background: #8eaccd;
}