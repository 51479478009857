/* Footer.module.css */
.footer {
  background-color: #f8f9fa;
  padding: 20px 0;
}

.footer a {
  color: inherit;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer .MuiContainer-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footer .MuiTypography-root {
  margin-bottom: 8px;
}

.footer .MuiIconButton-root {
  margin: 0 8px;
}